import {writable, get} from 'svelte/store'
import {DATABASE} from "../services/database.js";
import {AUTH} from "../services/auth.js";
import {loggedIn} from "./loggedIn.js";

export const userSettings = (() => {

    const initialValue = {
        lastLogin: Date.now(),
        autoAddSentence: true,
        selectedColor: 'var(--color-Verb)',
        colorCodeNouns: false,
        nounColors: {
            maskulinum: '#103e96',
            femininum: '#852276',
            neutrum: '#1e6c1e'
        },
        showSingularPluralOfNouns: false,
        wordsPerSession: {
            limit: false,
            count: 50,
        },
        addWordsCounts: [10, 25, 50],
        reminder: {
            timezone: '',
            emailHour: 8,
            sendEmail: false,
        }
    }

    const {subscribe, set, update} = writable({...initialValue})
    return {
        subscribe,
        set,
        async init() {
            try {
                const userSettings = await DATABASE.getDoc(DATABASE.userSettingsCollection, AUTH.getUid())

                if (userSettings) {
                    update(value => {
                        return {...value, ...userSettings}
                    })
                } else {
                    console.error('ERROR init UserSettings >> NO settings doc')
                }

                //TODO: rename to lastActive
                const updateDoc = {
                    lastLogin: Date.now()
                }
                await DATABASE.setDocMerge(DATABASE.userSettingsCollection, AUTH.getUid(), updateDoc)

            } catch (error) {
                console.error('ERROR while initializing userSettings', error)
            }
        },
        async changeSetting(key, value) {
            try {
                let updateDoc = {}
                updateDoc[key] = value
                update(value => {
                    return {...value, ...updateDoc}
                })
                if (get(loggedIn)) await DATABASE.updateDoc(DATABASE.userSettingsCollection, AUTH.getUid(), updateDoc)

            } catch (error) {
                console.error('ERROR while changing userSetting', error)
            }
        },
        clear() {
            set({...initialValue})
        }
    }
})();