// noinspection UnnecessaryLabelJS

import {get, writable} from "svelte/store";
import {DATABASE} from "../services/database";
import {convertBetweenWordIdAndDbKey} from "../utils/userWords/convertBetweenWordIdAndDbKey";
import {remoteChanges} from "./ui/remoteChanges.js";
import {validateUserWordChanges} from "../utils/userWords/validateUserWordChanges.js";
import {Interval} from "../utils/userWords/interval.js";
import {AUTH} from "../services/auth.js";
import {userSettings} from "./userSettings.js";
import {loggedIn} from "./loggedIn.js";
import {None, resolveOption, Some, Status, UserWord, UserWord$} from "$lib/scala.js";
import {wordIdToSentences} from "$lib/data/wordIdToSentences.js";
import {logInProduction} from "$lib/utils/logInProduction.js";

// { WordId -> UserWord }
export const userWordsMap = (() => {

    const initialValue = {}
    const {subscribe, set, update} = writable({...initialValue})

    function reset() {
        set({...initialValue})
    }

    function create(id, status, sentenceId) {
        sentenceId = sentenceId ? Some(sentenceId) : None()
        let now = Date.now()
        return new UserWord(id, Status(status), sentenceId, now, now)
    }

    function add(userWord) {
        update(value => {
            value[userWord.id] = userWord
            return value
        })
    }

    function remove(userWord) {
        update(value => {
            delete value[userWord.id]
            return value
        })
    }

    // als funktion, nicht weil USER.uid sich viell. ändert, sondern weil sonst nur einmal am Anfang erstellt mit null
    const USER_WORDS_COLLECTION = () => `${DATABASE.userWordsMainCollection}/${AUTH.getUid()}/${DATABASE.userWordsSubcollection}`

    async function saveUserWordToDb(userWord) {
        try {
            const collection = USER_WORDS_COLLECTION()
            const docId = convertBetweenWordIdAndDbKey(userWord.id)
            const docObj = UserWord$.toNative(userWord)
            await DATABASE.setDoc(collection, docId, docObj)
        } catch (error) {
            console.error("error while saving UserWord to DB", error)
        }
    }

    async function deleteUserWordFromDb(userWord) {
        try {
            const collection = USER_WORDS_COLLECTION()
            const docId = convertBetweenWordIdAndDbKey(userWord.id)
            await DATABASE.deleteDoc(collection, docId)
        } catch (error) {
            console.error("error while deleting UserWord from DB", error)
        }
    }

    let initialSnapshot = true

    async function handleUserWordChanges(querySnapshot) {

        let changes = querySnapshot.docChanges()
        // console.log('initial', initialSnapshot, 'changes', changes)

        if (initialSnapshot) {

            let {validated, toSave, toDelete} = await validateUserWordChanges(changes)
            if (toSave.length > 0 || toDelete.length > 0) console.log('>>>', 'validated', validated, 'toSave', toSave, 'toDelete', toDelete)

            validated.forEach(userWord => add(userWord))
            toSave.forEach(userWord => saveUserWordToDb(userWord))
            toDelete.forEach(userWord => deleteUserWordFromDb(userWord))

            initialSnapshot = false

        } else {
            changes.forEach(change => {

                const doc = change.doc
                const changedLocally = doc.metadata.hasPendingWrites
                const action = change.type

                // console.log(doc.data())
                // console.log('changedLocally', changedLocally, 'action', action)

                if (!changedLocally && action !== "removed") {
                    // changes from remote client >> force reload !
                    remoteChanges.set(true)
                    return
                }

                const userWord = UserWord$.fromNative(doc.data())

                if (action === "added") {
                    // console.log('added', userWord)
                    add(userWord)
                }
                if (action === "modified") {
                    // console.log("modified", userWord);
                    add(userWord)
                }
                if (action === "removed") {
                    // console.log("removed", userWord);
                    remove(userWord)
                }
            })
        }
    }

    return {
        subscribe,

        async init() {
            reset()
            try {
                this.cancelCollectionSubscription = DATABASE.subscribeCollection(USER_WORDS_COLLECTION(), handleUserWordChanges)
            } catch (error) {
                console.error('error while subscribing to UserWord collection', error)
            }
        },

        persist() {
            // Old data is always overwritten when subscription starts - routine_newUser runs before this is called in onAuth
            this.cancelPersistenceSubscription = this.subscribe(userWordsMap => {
                const userWords = Object.values(userWordsMap)
                const userWordsJson = userWords.map(userWord => UserWord$.toNative(userWord))
                try {
                    localStorage.setItem('user-words-v1', JSON.stringify(userWordsJson))
                } catch (error) {
                    console.error('ERROR persisting userWords to localStorage', error)
                }
            })
        },

        unpersist() {
            if (this.cancelPersistenceSubscription) {
                this.cancelPersistenceSubscription()
                // clear after unsubscribing, otherwise error when called a second time
                this.cancelPersistenceSubscription = null
            }
        },

        loadAndSavePersistedUserWords() {
            const userWordsStr = localStorage.getItem('user-words-v1')
            if (userWordsStr) {
                JSON.parse(userWordsStr).map(userWordJson => {
                    const userWord = UserWord$.fromNative(userWordJson)
                    saveUserWordToDb(userWord)
                })
            }
        },

        update() {
            update(value => value)
        },

        close() {
            if (this.cancelCollectionSubscription) this.cancelCollectionSubscription()
            reset()
        },

        async statusChangeRequest(id, newStatus, saveToDB) {

            const userWordsMap = get(this)
            let userWord = userWordsMap[id]
            const currentStatus = userWord?.status.toString()

            const noChange = currentStatus === newStatus
            if (noChange) return

            const addUserWord = !userWord
            const toggleStatus = (currentStatus === 'KNOWN' && newStatus === 'LEARN') || (currentStatus === 'LEARN' && newStatus === 'KNOWN')
            const deleteUserWord = !newStatus

            if (addUserWord) {
                logInProduction('add ' + id + ' - ' + new Date().toLocaleString() + ' - ' + Date.now())
                const addSentence = get(userSettings).autoAddSentence
                const sentenceId = addSentence ? wordIdToSentences[id]?.[0]?.id : null

                userWord = create(id, newStatus, sentenceId)

                if (saveToDB) {
                    await saveUserWordToDb(userWord)
                } else {
                    add(userWord)
                }

            } else if (toggleStatus) {
                logInProduction('toggle ' + id + ' - ' + new Date().toLocaleString() + ' - ' + Date.now())
                userWord.toggleStatus()
                if (get(loggedIn)) {
                    await saveUserWordToDb(userWord)
                } else {
                    this.update()
                }

            } else if (deleteUserWord) {
                logInProduction('delete ' + id + ' - ' + new Date().toLocaleString() + ' - ' + Date.now())
                if (saveToDB) {
                    await deleteUserWordFromDb(userWord)
                } else {
                    remove(userWord)
                }
            }
        },

        sentenceChangeRequest(id, newSentenceId, saveToDB) {

            const userWordsMap = get(this)
            let userWord = userWordsMap[id]

            const currentSentenceId = resolveOption(userWord?.sentenceId ?? None())

            const sameSentence = newSentenceId === currentSentenceId
            if (sameSentence) return

            const changeSentenceId = userWord && newSentenceId !== currentSentenceId
            const addUserWord = !userWord

            if (changeSentenceId) {
                userWord.sentenceId = Some(newSentenceId)
            } else if (addUserWord) {
                userWord = create(id, 'LEARN', newSentenceId)
            }

            if (saveToDB) {
                saveUserWordToDb(userWord)
            } else {
                add(userWord)
                this.update()
            }
        },

        rate(userWord, rating) {
            // console.log('rating', rating) // AGAIN, HARD, GOOD, EASY, KNOWN

            const testMode = !get(loggedIn)

            if (rating === 'again') {
                //rating again = repeat handled inside LearnModal
                console.log('rating >> again -- should never be logged')

            } else if (rating === 'known') {
                console.log('rating >> known')
                this.statusChangeRequest(userWord.id, 'KNOWN', !testMode)

            } else {
                // rating === 'hard', 'good', 'easy'

                const currentInterval = userWord.interval

                const newInterval = Interval.calculateNewInterval(currentInterval, rating)
                const toLearnNext = Interval.calculateToLearnNext(newInterval)
                console.log('rating', rating.toUpperCase(), 'current', currentInterval, '*', Interval.modifier[rating], 'newInterval', newInterval, 'toLearnNext', new Date(toLearnNext).toLocaleString())

                userWord.interval = newInterval
                userWord.toLearnNext = toLearnNext

                // DEVELOPMENT
                dev: userWord.toLearnNext = Date.now()

                if (testMode) {
                    add(userWord)
                } else {
                    saveUserWordToDb(userWord)
                }
            }
        }
    }
})();
