import {userWordsMap} from "$lib/stores/userWordsMap.js";
import {userSettings} from "$lib/stores/userSettings.js";
import {proVersion} from "$lib/stores/proVersion.js";
import {reviewCount} from "$lib/stores/reviewCount.js";

export function routine_logout() {
    //NOTE will not only run when user actively loggs out
    //but also when opening the page and being logged out

    userWordsMap.close()
    userWordsMap.persist()

    userSettings.clear()

    proVersion.close()
    reviewCount.terminate()
}